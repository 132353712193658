/* eslint-disable @typescript-eslint/space-before-function-paren */
import axios from 'axios'
import {
  CategoryModel,
  GetNTickets,
  TicketModel,
  TicketToSubmitModel,
  UpdateTicketToSubmit,
  UserProfileModel
} from '../entities'

// const url = process.env.REACT_APP_CONNECTOR_URL
export const api = axios.create({
  baseURL: process.env.REACT_APP_CONNECTOR
})

const apiLdap = axios.create({
  baseURL: process.env.REACT_APP_LDAP_URL
})

export async function getNTickets(
  userLogin: string,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  max_tickets: number,
  status: string
): Promise<GetNTickets[]> {
  const payload = {
    user_login: userLogin,
    max_tickets,
    status
  }
  const response = await api.post('/ticket/index', payload)
  return response.data?.tickets
}

export async function getTicket(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  called_id: string | number
): Promise<TicketModel> {
  const payload = {
    called_id
  }
  const response = await api.post('/ticket/show', payload)
  return response.data
}

export async function getUsersByName(userName: string): Promise<any> {
  const response = await api.get(`/user/show-by-name?name=${userName}&size=${250}`)
  return response.data.users
}

export async function storeTicket(payload: TicketToSubmitModel): Promise<{
  ticket_id: string
  message: string
}> {
  const response = await api.post('/ticket/store', payload)
  return response.data
}

export async function getUserProfile(userLogin: string): Promise<UserProfileModel> {
  const response = await api.post('/user/show', { user_login: userLogin })
  return response.data
}

export async function updateStatusTicket(payload: UpdateTicketToSubmit): Promise<any> {
  const response = await api.post('/ticket/followup/', payload)
  return response.data
}

export async function getGLPIFileBase64(fileId: string | number): Promise<any> {
  const response = await api.get(`/ticket/file/${fileId}`)
  return response.data
}

export async function getCategoryByName(
  isRequest: boolean,
  name: string
): Promise<CategoryModel[]> {
  const response = await api.get(`/category?isRequest=${isRequest.toString()}&name=${name}`)
  return response.data
}
export async function getManagerList(nameGroup?: string): Promise<any> {
  const response = await apiLdap.get('/groups?name=GRP_GLPI_GESTAOFUNC')
  return response.data
}
