import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getTicket } from '../services/connector'
import { TicketModel, TicketModelCostumer, TicketFollowups } from '../entities'

export const dataFollowup = (data: TicketFollowups) => {
  const dataTicketFollowup = data.all_followups
  const allFollowups = dataTicketFollowup.map((data) => ({
    followup: data.followup,
    date: data.date,
    user: data.user,
    fileId: data.fileId
  }))

  const latestFollowup = data.latest_followup || null
  return {
    all_followups: allFollowups.length > 1 ? allFollowups : null,
    latest_followup: latestFollowup
  }
}

export const formatTicketData = (data: TicketModel) => {
  const categoriesFormatted = data.ticket_category ? data.ticket_category.split('>') : []
  const descriptionFormatted = data.ticket_content.split(/\n/g).map(value => value.trim())
  const responseFollowup = dataFollowup(data.ticket_followup)
  const allFollowups = responseFollowup.all_followups
  const latestFollowup = responseFollowup.latest_followup

  const dataFormatted: TicketModelCostumer = {
    id: data.ticket_id || 'ID inválido, por favor, entre em contato conosco.',
    title: data.ticket_title || 'Chamado sem título',
    date_modify: data.ticket_last_mod || 'Chamado sem data de modificação.',
    date_resolve: data.ticket_time_to_resolve || 'Chamado sem data de resolução.',
    category: categoriesFormatted || 'Chamado sem categoria.',
    status: data.ticket_status || 'Chamado sem status.',
    type: data.ticket_type || 'Chamado sem tipo.',
    description: descriptionFormatted || 'Chamado sem descrição.',
    requester: data.ticket_requester || 'Chamado sem usuário de requisição.',
    last_updater: data.ticket_last_updater || 'Chamado sem usuário de atualização.',
    FollowUp: allFollowups,
    LatestFollowup: latestFollowup,
    entity: data.ticket_entity || 'Chamado sem entidade.',
    urgency: data.ticket_urgency || 'Chamado sem qualquer tipo de urgência.',
    priority: data.ticket_priority || 'Chamado sem prioridade.',
    technician: data.ticket_technician || 'Chamado sem técnico.',
    group: data.ticket_group || 'Sem grupo solucionador.'
  }

  return dataFormatted
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const useGetTicketDetails = (called_id: string | number) => {
  const [ticket, setTicket] = useState<TicketModelCostumer | null>(null)
  const [isLoadingTicket, setLoadingTicket] = useState(false)

  const getTicketDetails = async (): Promise<void> => {
    setLoadingTicket(true)
    try {
      const response = await getTicket(called_id)
      if (!response) {
        setTicket(null)
        throw new Error()
      }
      const ticketFormatted = formatTicketData(response)
      setTicket(ticketFormatted)
    } catch (error: any) {
      toast.error('Ops 😥, ocorreu um erro ao recuperar o chamado ')
    } finally {
      setLoadingTicket(false)
    }
  }

  useEffect(() => {
    getTicketDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    ticket,
    isLoadingTicket
  }
}
